<template>
  <div class="info">
    <h1>{{ info.title }}</h1>
    <div class="des">
      <span>作者：{{ info.author }}</span>
      <span>发布时间：{{ info.pub_date }}</span>
    </div>
    <div class="text">
      <p v-html="info.content" class="contentInfoBox"></p>
    </div>
    <div class="page">
      <div class="left">
        <span>上一页</span>HR如何判断应聘者的稳定性？
      </div>
      <div class="right">
        <span>下一页</span>HR如何判断应聘者的稳定性？
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  name: 'newsInfoPage',
  data() {
    return {
      id: 0,
      info: {}
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getNewsInfo(this.$route.query.id)
  },
  methods: {
    getNewsInfo(id) {
      this.$store.dispatch("getNewsDetail", id).then((res) => {
        this.info = res;
      });
    }
  },
  watch: {
    id: {
      handler(newid, oldid) {
        console.log('-----', newid, oldid)
      },
      immediate: true,
    }
  }
}
</script>

<style lang="less" scoped>
.info{
  font-size:14px;
  color: #333333;
  line-height: 1.9;
  background-color: #fff;
  padding:20px;
  h1{
    color:#222;
    font-size: 30px;
    line-height:2.4;
    margin:0;
    padding:0;
  }
  .des{
    font-size:14px;
    margin-bottom:15px;
    padding-bottom:30px;
    border-bottom:1px solid #e2e2e2;
    span{
      padding-right:30px;
    }
  }
  .text{
    p{
      margin:0;
      padding:10px 0;
    }

  }
  .page{
    margin-top:30px;
    width:100%;
    display: flex;
    justify-content: space-between;
    .left, .right{
      padding:10px;
      background:#f4f4f4;
      cursor: pointer;
      a, a:link,a:active, a:hover{
        color:#fff
      }
    }
    span{
      width:80px;
      border-right:1px solid #ddd;
      padding-right:10px;
      margin-right:10px;
    }
  }
  img{
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
<style lang="less">
.contentInfoBox{
  img{
    max-width: 95%;
    margin: 0 auto;
  }
}
</style>
